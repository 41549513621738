import React from "react";
import { faTruckFast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import customerIcon from "../../assets/customer-service.png";
import secureIcon from "../../assets/secure.png";

const Benefits: React.FC = () => {
  return (
    <>
      <div className="card-container">
        <div className="card">
          <h3 className="card-h3" id="freeshipping">
            <FontAwesomeIcon icon={faTruckFast} className="fa-truck_icon" />
            FREE SHIPPING
          </h3>
          <p className="card-p">When you Spend $100+</p>
        </div>
        <div className="card">
          <h3 className="card-h3">
            <img
              src={customerIcon}
              alt="Customer service icon"
              className="customerIcon"
            />
            Customers Reviews
          </h3>
          <p className="card-p" id="users">
            Ready to help our users
          </p>
        </div>
        <div className="card">
          <h3 className="card-h3">
            <img
              src={secureIcon}
              alt="Customer service icon"
              className="secureIcon"
            />
            ONLY GLUTEN FREE PRODUCTS
          </h3>
          <p className="card-p">Our Database is Gluten-Free</p>
        </div>
      </div>
    </>
  );
};

export default Benefits;
