import { useState, useEffect } from "react";
import { getProductByID } from "../services/ProductsApi";
import { ProductItemType } from "../types/Products";

const useProductDetail = (id: number) => {
  const [product, setProduct] = useState<ProductItemType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getProductDetail = async () => {
      try {
        const data = await getProductByID(id);
        setProduct(data);
        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };

    getProductDetail();
  }, [id]);

  return { product, loading, error };
};

export default useProductDetail;
