import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Navbar from "../Navbar/Navbar";
import useProducts from "../../hooks/useProducts";
import ProductList from "./ProductList";
import Footer from "../Footer/Footer";

const AllProducts: React.FC = () => {
  const initialPage = 1;
  const productsPerPage = 15;
  const { products, totalItems, totalPages, setPage, loading, error } =
    useProducts(initialPage, productsPerPage);
  const [query, setQuery] = useState<string>("");
  const [pageRange, setPageRange] = useState(2);

  const handlePageClick = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const handleSearch = (newQuery: string) => {
    setQuery(newQuery);
    setPage(1);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setPageRange(4);
      } else if (window.innerWidth >= 480) {
        setPageRange(3);
      } else {
        setPageRange(2);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <Navbar />
      <div id="all-products">
        <div className="all-products-title">
          <h1 className="all-products">Products</h1>
          <h2 className="all-products-total">
            Total of products ({totalItems})
          </h2>
        </div>
        <ProductList products={products} />
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={pageRange}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
      <Footer />
    </div>
  );
};

export default AllProducts;
