import React, { useEffect, useState } from "react";
/*import { getRestaurants } from "../../services/RestaurantApi";
import { RestaurantItemType } from "../../types/Restaurants";*/
import "./Restaurants.css";
import axios from "axios";

/*interface RestaurantListProps {
  restaurants: RestaurantItemType[];
  loading: boolean;
  error: string | null;
}

const Restaurants: React.FC<RestaurantListProps> = ({
  restaurants,
  loading,
  error,
}) => {
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div id="restaurants">
      <h1 className="restaurants-title">Restaurants</h1>
      <div className="cards-container">
        {restaurants.map((restaurant: RestaurantItemType) => (
          <div key={restaurant.ID} className="restaurant-card">
            <img
              src={restaurant.Image}
              alt={restaurant.Name}
              className="restaurant-img"
            />
            <div className="card-title">{restaurant.Name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};*/

interface Restaurant {
  id: number;
  name: string;
  img: string;
}

const Restaurants: React.FC = () => {
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);

  useEffect(() => {
    axios
      .get("/restaurants.json")
      .then((response) => {
        setRestaurants(response.data);
      })
      .catch((error) => {
        console.error("Error fetching the restaurants:", error);
      });
  }, []);

  return (
    <div id="restaurants">
      <h1 className="restaurants-title">Restaurants</h1>
      <div className="cards-container">
        {restaurants.map((restaurant) => (
          <div key={restaurant.id} className="restaurant-card">
            <img
              src={restaurant.img}
              alt={restaurant.name}
              className="restaurant-img"
            />
            <div className="card-title">{restaurant.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Restaurants;
