import React from "react";
import { ProductItemType } from "../../types/Products";
import { Link } from "react-router-dom";

interface ProductListProps {
  products: ProductItemType[];
  ID?: number;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const limitWords = (string: string): string => {
  return string.split(" ").slice(0, 2).join(" ");
};

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  return (
    <div className="products-cards_container">
      {products.length === 0 ? (
        <p>No products available</p>
      ) : (
        products.map((product: ProductItemType) => (
          <div key={product.ID} className="product-card">
            <Link to={`/product/${product.ID}`}>
              <a href={product.Url} target="_blank">
                <img
                  src={product.Image}
                  alt={product.Name}
                  className="product-image"
                />
              </a>
              <p className="product-category">{product.Category}</p>
              <h3 className="product-title">
                {capitalizeFirstLetter(limitWords(product.Name))}
              </h3>

              <p className="product-price">${product.Price}</p>
            </Link>
          </div>
        ))
      )}
    </div>
  );
};

export default ProductList;
