import React from "react";
import useProductDetail from "../../hooks/useProductDetail";
import { useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import "./ProductDetail.css";
import Footer from "../Footer/Footer";

const ProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { product, loading, error } = useProductDetail(Number(id));

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!product) {
    return <p>Product not found</p>;
  }

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <>
      <Navbar />
      <div className="product-detail">
        <div className="image-container">
          <img
            src={product.Image}
            alt={product.Name}
            className="product-detailed-image"
          />
        </div>
        <div className="product-detailed-items">
          <h1 className="product-detailed-name">
            {" "}
            {capitalizeFirstLetter(product.Name)}
          </h1>
          <p className="product-detailed-category">{product.Category}</p>
          <p className="product-detailed-price">${product.Price}</p>
          <p className="product-detailed-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
            interdum condimentum enim a varius. Orci varius natoque penatibus et
            magnis dis parturient montes, nascetur ridiculus mus. Vestibulum at
            leo eu sem varius hendrerit. Aenean sit amet sapien varius,
            ullamcorper tellus eget, vehicula dolor. In facilisis consequat
            risus nec dictum. Etiam ante eros, suscipit sit amet libero eu,
            tristique sodales tortor. Nullam efficitur at turpis vitae
            consectetur. Suspendisse ultrices, ligula dignissim dapibus mattis,
            risus quam rhoncus sapien, eu aliquet odio tellus eget enim.
            Praesent vitae ligula cursus, rhoncus turpis quis, hendrerit dolor.
            Mauris sem sapien, aliquet ut felis suscipit, pharetra aliquam
            nulla. Duis nibh urna, condimentum at feugiat eu, convallis at nisl.
            Nam quam mi, vehicula sit amet magna vel, aliquam egestas nunc.
            Curabitur accumsan orci eget hendrerit tincidunt. Praesent
            condimentum, sem eu maximus dignissim, massa risus finibus nulla, a
            convallis massa enim ac dolor. In lacinia mauris felis, vitae
            gravida nibh hendrerit quis. Vestibulum vel nisi ac purus mollis
            rhoncus ac eu lorem. Ut ut pretium mauris. Suspendisse lacinia,
            augue a malesuada egestas, quam eros porttitor ligula, tincidunt
            dignissim elit quam non dui.
          </p>
          <a href={product.Url} target="_blank">
            <button className="link-button">Go to shop</button>
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetail;
