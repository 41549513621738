import { ProductItemType } from "../types/Products";
import { useState, useEffect } from "react";
import { searchProducts } from "../services/SearchService";
import { ProductType } from "../types/Products";

const useSearchProducts = (query: string) => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!query) {
      setProducts([]);
      return;
    }
    const getSearchProduct = async (query: string) => {
      setLoading(true);
      setError(null);
      try {
        const data: ProductType = await searchProducts(query);
        setProducts(data.Items || []);
      } catch (error) {
        setError(
          error instanceof Error ? error.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    const delayDebounceFn = setTimeout(() => {
      getSearchProduct(query);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  return {
    products,
    setProducts,
    loading,
    setLoading,
    error,
    setError,
  };
};

export default useSearchProducts;
