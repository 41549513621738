import React from "react";
import { Link } from "react-router-dom";
import useProducts from "../../hooks/useProducts";
import "./Products.css";
import ProductList from "./ProductList";

const Products: React.FC = () => {
  const { products, loading, error } = useProducts(1, 15);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div id="products">
      <div className="all-products">
        <h2 className="products-title">Products</h2>
        <Link to="/all-products">
          <button className="products-button">See all products</button>
        </Link>
      </div>

      <ProductList products={products.slice(0, 10)} />
    </div>
  );
};

export default Products;
