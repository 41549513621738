import { useState, useEffect } from "react";
import { getProducts } from "../services/ProductsApi";
import { ProductType } from "../types/Products";

const useProducts = (initialPage: number, initialLimit: number) => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getProductData = async () => {
      try {
        const data: ProductType = await getProducts(page, limit);
        setProducts(data.Items);
        setTotalItems(data.TotalItems);
        const calculatedTotalPages = Math.ceil(data.TotalItems / limit);
        setTotalPages(calculatedTotalPages);
      } catch (error) {
        setError(
          error instanceof Error ? error.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    getProductData();
  }, [page, limit]);

  return {
    products,
    totalItems,
    page,
    limit,
    setLimit,
    totalPages,
    setPage,
    loading,
    error,
  };
};

export default useProducts;
