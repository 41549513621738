import React, { useState } from "react";
import useSearchProducts from "../../hooks/useSearch";
import ProductList from "../Products/ProductList";
import ReactPaginate from "react-paginate";
import Navbar from "../Navbar/Navbar";
import "./Search.css";
import { useSearchParams, useNavigate } from "react-router-dom";

const Search: React.FC = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const { products, loading, error } = useSearchProducts(query);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <Navbar />
      <h1 className="search-title">Search Results: {query}</h1>
      {products.length === 0 ? (
        <p className="no-products-found">No products found</p>
      ) : (
        <div className="container-products">
          <ProductList products={currentProducts} />
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(products.length / productsPerPage)} // Calcula cuántas páginas hay en total
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  );
};

export default Search;
